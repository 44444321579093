$(document).foundation();

/* -------------------------- */
the_root = '/'; // IN PRODUZIONE
the_root = '' ; // IN LOCALE
/* -------------------------- */

var offset = 175;

function reset_filtro() {

	if (jQuery("#filter").val()=='') return;
	jQuery("#filter").val('');
	filtra();
}

function filtra() {

	var f = jQuery("#filter").val();
	jQuery('#the_record').empty();
	loading('#the_page');

	jQuery.ajax({
			type: "POST",
			url:  the_root+"assets/php/search.php",
			data: {filter: f}
		})
		.done(function(sHtml) {
			jQuery("#the_page").empty();
			jQuery("#the_page").append(sHtml);
			jQuery('html,body').animate({scrollTop: jQuery("#the_data").offset().top-offset},'slow');
		})
		.fail(function(jqXHR, textStatus) {
			jQuery("#the_page").empty();
			jQuery("#the_page").append("Ops, qualcosa è andato storto ...");
		});
}

function cerca() {

	var cond = jQuery("#cond").val();
	var term = jQuery("#term").val();
	if (term=='' && cond=='') { alert("Inserisci almeno una chiave di ricerca"); return; }

	if ((cond!='' && cond.length<3) || (term!='' && term.length<3)) { alert("La chiave di ricerca deve essere di almeno 3 caratteri"); return; }

	data = jQuery("#the_search_form").serialize();

	jQuery('#the_record').empty();
	loading('#the_data');
	jQuery('html,body').animate({scrollTop: jQuery('#the_data').offset().top-offset},'slow');

	jQuery.ajax({
			type: "POST",
			url:  the_root+"assets/php/search.php?start",
			data: data
		})
		.done(function(sHtml) {
			jQuery("#the_data").empty();
			if (sHtml.search("bytes exhausted")!=-1) sHtml = "Abbiamo trovato troppi studi, prova a raffinare i termini della ricerca.";
			jQuery("#the_data").append(sHtml);
			jQuery('html,body').animate({scrollTop: jQuery("#the_data").offset().top-offset},'slow');
		})
		.fail(function(jqXHR, textStatus) {
			jQuery("#the_data").empty();
			jQuery("#the_data").append("Abbiamo trovato troppi studi, prova a raffinare i termini della ricerca.");
		});
}

function carica(page) {

	jQuery.ajax({
			type: "POST",
			url:  the_root+"assets/php/search.php",
			data: { page: page }
		})
		.done(function(sHtml) {
			jQuery("#the_page").empty();
			jQuery("#the_page").append(sHtml);
			//jQuery('html,body').animate({scrollTop: jQuery("#the_data").offset().top},'slow');
		})
		.fail(function(jqXHR, textStatus) {
			jQuery("#the_page").empty();
			jQuery("#the_page").append("ops, qualcosa è andato storto ... ");
		});
}

function scheda(the_id) {

	jQuery("#the_record").empty();
	jQuery("#the_record").append('<a id="the_anchor"/><div class="loading"><img src="'+the_root+'assets/img/loader.gif" width="100" height="100"></div>');

	jQuery('html,body').animate({scrollTop: jQuery("a[id='the_anchor']").offset().top-155},'slow');

	jQuery.ajax({
			type: "POST",
			url:  the_root+"assets/php/search.php",
			data: { scheda: true, page:-1, single: the_id }
		})
		.done(function(sHtml) {
			jQuery("#the_record").empty();
			jQuery("#the_record").append(sHtml);
		})
		.fail(function(jqXHR, textStatus) {
			jQuery("#the_record").empty();
			jQuery("#the_record").append("ops, qualcosa è andato storto ... ");
		});
}

function chiudi_scheda(the_id) {

	jQuery("#the_record").empty();
	jQuery('html,body').animate({scrollTop: jQuery("#the_data").offset().top-offset},'slow');

}

function get_glossary() {

	jQuery("#the_data").html('<a id="the_anchor"/><div class="loading"><img src="'+the_root+'assets/img/loader.gif" width="100" height="100"></div>');

	jQuery.ajax({
			type: "POST",
			url:  the_root+"assets/php/get_glossary.php",
			data: { }
		})
		.done(function(sHtml) {
			jQuery("#the_data").html(sHtml);
			$(document).foundation(); //restart foundation per l'accordion creato via php
		})
		.fail(function(jqXHR, textStatus) {
			jQuery("#the_data").html("ops, qualcosa è andato storto ... ");
		});
}

function goto_glossary(the_id) {

	jQuery('html,body').animate({scrollTop: jQuery('#'+the_id).offset().top-190},'slow');

}

function mailto() {

	var subject = 'SPOT myTrial - ' + document.getElementById('the_id').innerHTML;
	var emailBody = '';
	emailBody = emailBody + document.getElementById('the_title').innerHTML + '\n\n';
	emailBody = emailBody + document.getElementById('the_url').innerHTML + '\n\n';

	window.location = 'mailto:?subject=' + subject + '&body=' + encodeURIComponent(emailBody);

}

function mailto_bookmark() {

	var subject = 'SPOT myTrial - STUDI SALVATI';
	var emailBody = '';

	var bookmarks = read_bookmarks();
	for (ii=0; ii<bookmarks.length; ii++) {
		bm = bookmarks[ii];
		emailBody = emailBody + bm[0] + '\n';
		emailBody = emailBody + bm[1] + '\n';
		emailBody = emailBody + bm[2] + '\n\n';
	}
	window.location = 'mailto:?subject=' + subject + '&body=' + encodeURIComponent(emailBody);

}

function privacy() {

	localStorage.privacy = 'ok';
	jQuery("#privacy").slideToggle("slow");
}

function loading(the_id) {
	jQuery(the_id).html('<div class="loading"><p><img src="'+the_root+'assets/img/loader.gif" width="100" height="100"></p><p>Ricerca in corso, attendere prego...<br>La ricerca è effettuata in tempo reale nella base dati di ClinicalTrials.gov, aggiornata giornalmente</p></div>');
}

function new_search() {
	jQuery("#the_data").empty();
	jQuery("#the_record").empty();
}

/*
function next_video() {
	the_video_id = the_video_id<4 ? (the_video_id+1) : 1;
	show_video();
}
function prev_video() {
	the_video_id = the_video_id>1 ? (the_video_id-1) : 4;
	show_video();
}
*/
function show_video() {
	/*
	var the_html = '';
	the_html += '<video controls class="small-12">';
	the_html += '<source src="'+the_root+'assets/video/video'+the_video_id+'.mp4" type="video/mp4">';
 	the_html += 'Your browser does not support the video tag.';
	the_html += '</video>';
	jQuery("#video").html(the_html);
	*/

	/*
	the_html = '';
	switch(the_video_id) {
		case 1:
			document.getElementById('titolo-2').innerHTML = 'Cosa sono gli studi clinici';
			the_html += '<a class="hollow button" href="tutorial-1.html">Se vuoi approfondire: come funzionano gli studi clinici?</a>';
      break;
		case 2:
			document.getElementById('titolo-2').innerHTML = 'Perché può essermi utile partecipare a uno studio';
			the_html += '<a class="hollow button" href="tutorial-1.html">Se vuoi approfondire: come funzionano gli studi clinici?</a>';
      break;
    case 3:
			document.getElementById('titolo-2').innerHTML = 'Cosa mi serve sapere per partecipare a uno studio';
			the_html += '<a class="hollow button" href="tutorial-3.html">SE VUOI APPROFONDIRE: I MIEI DIRITTI</a>';
			the_html += '<a class="hollow button" href="tutorial-4.html">SE VUOI APPROFONDIRE: LE LEGGI</a>';
			break;
		case 4:
			document.getElementById('titolo-2').innerHTML = 'Perché cercare uno studio';
			the_html = '<a class="hollow button" href="tutorial-4.html">SE VUOI APPROFONDIRE: PERCHÉ LO STUDIO CLINICO È ETICO</a>';
			break;
	}

	document.getElementById('video-dida').innerHTML = the_html;
	*/
}

function pdf_bookmark() {

	var riga = 40;
	var colonna = 10;
	var num_righe = 0;
	var bookmarks = read_bookmarks();

	var pdf = new jsPDF();
	pdf.setFont("helvetica");
	pdf.setFontSize(18);
	pdf.text(colonna, 20, 'SPOT myTRIAL - Studi salvati');
	pdf.setFontSize(14);

	for (ii=0; ii<bookmarks.length; ii++) {

		bm = bookmarks[ii];

		if (num_righe>=20) { pdf.addPage(); riga = 20; num_righe = -5;  }
		pdf.setTextColor(0,0,255);
		pdf.text(colonna, riga, bm[0]+' - '+bm[2]);
		pdf.setTextColor(100);
		riga = riga+10;
		num_righe++;
		splitTitle = pdf.splitTextToSize(bm[1], 190);
		for (jj=0; jj<splitTitle.length; jj++) {
			if (num_righe>=20) { pdf.addPage(); riga = 20; num_righe = -5;  }
			pdf.text(colonna, riga, splitTitle[jj]);
			riga = riga+7;
			num_righe++;
		}
		riga = riga+13;
	}
	pdf.save('SPOT-My-TRIAL-I-miei-studi-preferiti.pdf');
}

function read_bookmarks() {

	if (typeof(localStorage.bookmarks) === "undefined") {
		var bookmarks = new Array();
		return bookmarks;
	} else {
		return JSON.parse(localStorage.bookmarks);
	}
}

function add_bookmark(bm) {

	if (typeof(localStorage.bookmarks) === "undefined") {
		var bookmarks = new Array();
		bookmarks.push(bm);
		localStorage.bookmarks = JSON.stringify(bookmarks);
	} else {
		var bookmarks = JSON.parse(localStorage.bookmarks);
		for (ii=0; ii<bookmarks.length; ii++) {
			if (bookmarks[ii][0]==bm[0]) {
				alert('Questo studio è già presente nella tua lista!');
				return;
			}
		}
		bookmarks.push(bm);
		localStorage.bookmarks = JSON.stringify(bookmarks);
		jQuery("#the_message").slideToggle("slow");
		setTimeout(function(){ jQuery("#the_message").slideToggle("slow"); }, 3000);
	}
}

function delete_bookmark(id) {

	var bookmarks = read_bookmarks();
	bookmarks.splice(id,1);
	localStorage.bookmarks = JSON.stringify(bookmarks);
	show_bookmarks();
}

function show_bookmarks() {

	var bookmarks = read_bookmarks();

	var the_html = '';
	for (ii=0; ii<bookmarks.length; ii++) {
		bm = bookmarks[ii];
		the_html += '<div class="row rows">';
		the_html += '<div class="small-12 medium-2 large-2 columns b-right">'+bm[0]+'<br>('+bm[3]+')</div>';
		the_html += '<div class="small-12 medium-8 large-8 columns b-right">'+bm[1]+'</div>';
		the_html += '<div class="small-12 medium-2 large-2 columns"><a href="'+bm[2]+'" target="_blank" class="small button btn-100">APRI LINK</a><a onclick="delete_bookmark('+ii+')" class="small button btn-100">RIMUOVI</a></div>';
		the_html += '</div>';
	}
	jQuery("#bookmarks").empty();
	jQuery("#bookmarks").append(the_html);
}

function read_tutorial(num_t) {

	if (typeof(localStorage.tutorials) === "undefined") {
		//
		// prima volta, init globale
		var tutorial_1 = [[0,0],[0],[0,0],[0,0],[0],[0,0,0,0,0],[0,0],[0],[0]];
		var tutorial_2 = [[0],[0,0,0,0],[0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0]];
		var tutorial_3 = [[0],[0,0],[0,0],[0,0],[0],[0],[0],[0],[0]];
		var tutorial_4 = [[0],[0]];
		var tutorials = [tutorial_1,tutorial_2,tutorial_3,tutorial_4];
		localStorage.tutorials = JSON.stringify(tutorials);
	}
	var t = JSON.parse(localStorage.tutorials);
	return t[num_t];
}

function show_anima(na) {

	jQuery("#testo-anima-"+num_anima).css("display","none");

	for (ii=0; ii<pagina.length; ii++) { jQuery("#paragrafo-"+(ii+1)).removeClass("in-visione"); }

	num_anima = parseInt(na);

	// cookie x tutorial visto
	var tutorials = JSON.parse(localStorage.tutorials);
	tutorials[num_tutorial][num_pagina][num_anima-1] = 1;
	localStorage.tutorials = JSON.stringify(tutorials);
	//
	jQuery("#paragrafo-"+(num_anima)).addClass("visto");
	jQuery("#paragrafo-"+(num_anima)).addClass("in-visione");

	jQuery("#anima").css("display","block");
	jQuery("#testo-anima-"+num_anima).css("display","none");

	jQuery("#show_anima_text").css("display","block");
	jQuery("#show_anima_slide").css("display","none");

	// cambio scena su hype
	//document.getElementById('the-iframe').contentWindow.HYPE.documents['cap'+(num_tutorial+1)+'-'+(num_pagina+1)].showSceneNamed(na);

	// video hype
	var the_html = '';
	the_html += '<video width="866" controls autoplay class="small-12">';
	the_html += '<source src="'+the_root+'assets/video/cap'+(num_tutorial+1)+'-'+(num_pagina+1)+'_p'+na+'.mp4" type="video/mp4">';
 	the_html += 'Your browser does not support the video tag.';
	the_html += '</video>';

	jQuery("#anima").html(the_html);

}

function show_anima_text() {

	jQuery("#anima").css("display","none");
	jQuery("#testo-anima-"+num_anima).css("display","block");

	jQuery("#show_anima_text").css("display","none");
	jQuery("#show_anima_slide").css("display","block");
}

function show_anima_slide() {

	jQuery("#anima").css("display","block");
	jQuery("#testo-anima-"+num_anima).css("display","none");

	jQuery("#show_anima_text").css("display","block");
	jQuery("#show_anima_slide").css("display","none");
}
//
//
// inizializzazioni onload()
//
function init_page() {

	if ($_GET('tutorials')=='reset') delete localStorage.tutorials; // tutorials storage reset
	if ($_GET('bookmarks')=='reset') delete localStorage.bookmarks; // bookmarks storage reset

	if (typeof(localStorage.privacy) === "undefined") { jQuery("#privacy").slideToggle("slow"); }

	if(typeof(Storage) === "undefined") { alert('Attenzione, il tuo browser non supporta il salvataggio dei dati, non sarà possibile salvare i tuoi casi preferiti.'); }

	if (typeof the_video_id !== 'undefined') { show_video(); } // mostra video

	if (typeof bookmark_flag !== 'undefined') { show_bookmarks(); } // mostra bookmarks

	if (typeof glossary_flag !== 'undefined') { get_glossary(); } // carica glossario

	if (typeof tutorial_flag !== 'undefined') {
		//
		// visualizza percentuale vista per ogni capitolo del tutorial x
		var tutorial = read_tutorial(num_tutorial);
		for (ii=0; ii<tutorial.length; ii++) {
			pagina = tutorial[ii];
			visti = 0;
			for (jj=0; jj<pagina.length; jj++) { visti += pagina[jj]; }
			percentuale = (100/pagina.length)*visti;
			document.getElementById("pagina-"+(ii+1)).setAttribute("stroke-dasharray", percentuale + " 100");
		}
	}

	if (typeof pagina_tutorial_flag !== 'undefined') {
		//
		// visualizza paragrafi visti per ogni capitolo y del tutorial x
		var tutorial = read_tutorial(num_tutorial);
		pagina = tutorial[num_pagina];
		for (ii=0; ii<pagina.length; ii++) {
			if (pagina[ii]==1) jQuery("#paragrafo-"+(ii+1)).addClass("visto");
		}
		// set cookie e paragrafo visto per il primo
		var tutorials = JSON.parse(localStorage.tutorials);
		tutorials[num_tutorial][num_pagina][0] = 1;
		localStorage.tutorials = JSON.stringify(tutorials);
		//
		if (num_tutorial!=3) {
			show_anima('1');
			jQuery("#paragrafo-1").addClass("visto");
		}
	}

	if (typeof search_flag !== 'undefined') {
		//
		// autocompletamento
		var input_cond = document.getElementById("cond");
		var input_term = document.getElementById("term");
		var awesomplete_1 = new Awesomplete(input_cond, { filter: Awesomplete.FILTER_STARTSWITH, sort: function (a, b) { return a < b ? -1 : 1; } });
		var awesomplete_2 = new Awesomplete(input_term, { filter: Awesomplete.FILTER_STARTSWITH, sort: function (a, b) { return a < b ? -1 : 1; } });
		awesomplete_1.list = termini;
		awesomplete_2.list = termini;
	}

}

function $_GET(variable)
{
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);
}

document.addEventListener('DOMContentLoaded', function() {
    const menuToggle = document.getElementById('menu-toggle');
    const top = document.getElementById('top');
    const navbar = document.getElementById('navbar');
    const navbar_mobile = document.getElementById('navbar-mobile');

    menuToggle.addEventListener('click', function() {
        //navbar.querySelector('ul').classList.toggle('active');
        top.classList.toggle('top-mobile');
        navbar_mobile.classList.toggle('active');
    });
});
